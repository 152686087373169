import {
  CustomisationOption,
  CustomisationOptionGroup,
} from '../../types/customisation';
import { CheckboxOptions } from './checkbox-options';
import { RadioOptions } from './radio-options';

export interface RadioOptionProps {
  options: CustomisationOption[];
  name: string;
  itemCount: number;
  error: boolean;
  helperText: string;
}

export interface CheckboxOptionProps {
  options: CustomisationOption[];
  name: string;
  itemCount: number;
  helperText?: string;
}

export interface CustomisationProps {
  customisation: CustomisationOptionGroup;
  itemCount: number;
  error: boolean;
  helperText: string;
}

export const inputType = {
  singleSelect: RadioOptions,
  multiSelect: CheckboxOptions,
};

export type AppConfigContext = {
  regionID?: string;
  accountID?:string;
  eventID?: string;
  venueID?: string;
  storeID?: string;
  categoryID?: string;
  itemID?: string;
};
