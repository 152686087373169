import { gql } from '@apollo/client';

export const GET_VENDORS = gql`
  query GetVendors($vendorQueryParams: VendorQueryParams) {
    getPublicVendors(vendorQueryParams: $vendorQueryParams) {
      _id
      tradingName
      imageUrl
      businessName
      contactPhone
      contactEmail
      isOpen
      venueID
      isDisabled
    }
  }
`;

export const GET_VENDOR = gql`
  query GetVendor($id: String!) {
    getPublicVendor(id: $id) {
      _id
      tradingName
      imageUrl
      businessName
      contactPhone
      contactEmail
      isDisabled
      isOpen
    }
  }
`;
export const GET_VENUE = gql`
  query getVenue($id: String!) {
    getVenue(id: $id) {
      id
      name
      venueCode
      isDeleted
      isDisabled
      sections {
        label
        id
        isClickAndCollect
        isDeleted
        cncNavigationImageUrl
        serviceMethods
      }
      collectionPoints {
        id
        label
        cpNavigationImageUrl
        serviceableSectionsIds
        isDeleted
      }     
      deliveryAreas {
        id
        label
        daNavigationImageUrl
        serviceableSectionsIds
        isDeleted
      }
      ttsBrandingImageUrl
      cncBrandingImageUrl
      patronNavigationImageUrl
      vendorListImageUrl
      venueLandscapeImageUrl
      venueCurrency {
        name
        symbol
      }
      minimumOrderPrice
      venueType
      accountID
      

    }
  }
`;

export const GET_VENUE_WITH_STORES = gql`
  query getVenue($id: String!, $storeFilters: VenueStorelistFilters, $includeIntegrations: Boolean = false, $integrationType: String, $integrationStatus: String = "ACTIVE") {
    getVenue(id: $id, ) {
      id
      name
      venueCode
      venueType
      accountID
      isDeleted
      isDisabled
      sections {
        label
        id
        isClickAndCollect
        isDeleted
        cncNavigationImageUrl
        serviceMethods
      }
      collectionPoints {
        id
        label
        cpNavigationImageUrl
        serviceableSectionsIds
        isDeleted
      }
      deliveryAreas {
        id
        label
        daNavigationImageUrl
        serviceableSectionsIds
        isDeleted
      }
      ttsBrandingImageUrl
      cncBrandingImageUrl
      patronNavigationImageUrl
      vendorListImageUrl
      venueCurrency {
        name
        symbol
      }
      minimumOrderPrice
      sellingStores(filters: $storeFilters) {
        id
        ownerID
        storeName
        displayName
        storeFrontImageUrl
        isOpen
        isDisabled
        updatedOn
        createdOn
        isDeleted
        menu {
          id
          ownerID
          description
        }
        owner {
          businessName
          tradingName
          imageUrl
        }
      }
      venueIntegrations(status: $integrationStatus, type: $integrationType) @include(if: $includeIntegrations) {
        id
        name
        label
        receiverID
        ownerID
        status
        type
        meta {
          __typename
          ... on POSIntegrationMeta {
                
                storeID
                settings {
                  usePOSMenu
                  pushOrders
                }
                dataSyncInfo {
                  menuSyncdAt
                  syncdMenuID
                }
                platformMeta {
                  locationHash
                }
            }
          ... on PMSIntegrationMeta {
              venueID
              platformMeta {
                hotelID
              }
            }
        }
      }
    }
  }
`;



export const GET_STORE = gql`
query getStore($ownerID: String!, $storeID: String!) {
  getStore(ownerID: $ownerID, storeID: $storeID) {
    id
    ownerID
    storeName
    displayName
    storeFrontImageUrl
    isOpen
    isDisabled
    updatedOn
    createdOn
    isDeleted
    menu {
      id
      ownerID
      description
    }
    owner {
      _id
      businessName
      contactEmail
      contactPerson
      contactPhone
      stallID
      imageUrl
      hoursOfOperation
      isDisabled
      venueID
      sectionIDs
      isDeleted
      isOpen
      tradingName
    }
  }
}
`;

export const GET_VENUES = gql`
  query getVenuesByFilter($venueQueryParams: VenueQueryParams) {
    getVenuesByFilter(venueQueryParams: $venueQueryParams) {
      id
      name
      venueCode
      isDeleted
      isDisabled
      sections {
        label
        id
        isClickAndCollect
      }
      venueCurrency {
        name
        symbol
      }
      venueType
    }
  }
`;

export const GET_VENUE_BY_CODE = gql` 
  query getVenueByVenueCode($venueQueryParams: VenueQueryParams, $storeFilters: VenueStorelistFilters, $includeIntegrations: Boolean = false, $integrationType: String, $integrationStatus: String = "ACTIVE") {
    getVenueByVenueCode(venueQueryParams: $venueQueryParams) {
      id
      name
      venueCode
      venueType
      isDeleted
      isDisabled
      sections {
        label
        id
        isClickAndCollect
        isDeleted
        cncNavigationImageUrl
        serviceMethods
      }
      collectionPoints {
        id
        label
        cpNavigationImageUrl
        serviceableSectionsIds
        isDeleted
      }
      deliveryAreas {
        id
        label
        daNavigationImageUrl
        serviceableSectionsIds
        isDeleted
      }
      ttsBrandingImageUrl
      cncBrandingImageUrl
      patronNavigationImageUrl
      vendorListImageUrl
      venueCurrency {
        name
        symbol
      }
      minimumOrderPrice
      sellingStores(filters: $storeFilters) {
        id
        ownerID
        storeName
        displayName
        storeFrontImageUrl
        isOpen
        isDisabled
        updatedOn
        createdOn
        isDeleted
        menu {
          id
          ownerID
          description
        }
        owner {
          businessName
          tradingName
          imageUrl
        }
      }
      venueIntegrations(status: $integrationStatus, type: $integrationType) @include(if: $includeIntegrations) {
        id
        name
        label
        receiverID
        ownerID
        status
        type
        meta {
          __typename
          ... on POSIntegrationMeta {
                
                storeID
                settings {
                  usePOSMenu
                  pushOrders
                }
                dataSyncInfo {
                  menuSyncdAt
                  syncdMenuID
                }
                platformMeta {
                  locationHash
                }
            }
          ... on PMSIntegrationMeta {
              venueID
              platformMeta {
                hotelID
              }
            }
        }
      }


    }
  }
`;

export const GET_ITEMS = gql`
  query GetItems($itemQueryParams: ItemQueryParams) {
    getItems(itemQueryParams: $itemQueryParams) {
      _id
      name
      imageUrl
      thumbImageUrl
      description
      price
      vendorID
      price
      outOfStock
      serialNumber
    }
  }
`;

export const GET_ITEM = gql`
  query GetItem($id: String!, $venueID:String!) {
    getItem(id: $id, venueID:$venueID) {
      _id
      name
      imageUrl
      description
      price
      markupPrice
      vendorID
      price
      category
      outOfStock
      customisations {
        _id
        name
        type
        options {
          _id
          name
          price
          markupPrice
        }
      }
    }
  }
`;

export const CALCULATE_ITEM_SUB_TOTAL = gql`
 query CalculateItemSubTotal($itemSubTotalCalculatorInput: ItemSubTotalCalculatorInput!) {
  calculateItemSubTotal(itemSubTotalCalculatorInput: $itemSubTotalCalculatorInput) {
    _id
    price
    markupPrice
    pricing {
      subTotal
      deliveryCharge
      total
    }
    storeIsClosed
    vendorIsClosed
  }
}
`;

export const GET_CHARGES = gql`
  query Charges {
    getCharges {
      markupCharge
      seatDeliveryCharge
      collectionPointDeliveryCharge
      serviceCharge
    }
  }
`;

export const GET_CONFIG_CHARGES = gql`
query getConfigCharges($context: AppConfigContext!) {
  getConfigCharges(context: $context) {
    markupCharge
    seatDeliveryCharge
    collectionPointDeliveryCharge
    serviceCharge
  }
}
`;

export const GET_PRE_ORDER_SUMMARY = gql`
  query preOrderSummary($preOrderSummaryInput: PreOrderSummaryInput) {
    getPreOrderSummary(preOrderSummaryInput: $preOrderSummaryInput) {
      available {
        _id
        key
        name
        count
        price
        category
        additionalAttributes {
          stdDrinkEquivalence
        }
        markupPrice
        pricing {
          subTotal
          singleQtyPrice
        }
        outOfStock
        vendorIsClosed
        storeIsClosed
        storeID
        showInMenu
        customisations {
          _id
          name
          type
          additionalAttributes {
            stdDrinkEquivalence
          }
        }
      }
      unavailable {
        _id
        key
        name
        count
        markupPrice
        price
        category
        pricing {
          subTotal
          singleQtyPrice
        }
        outOfStock
        vendorIsClosed
        storeIsClosed
        storeID
        showInMenu
        customisations {
          _id
          name
          type
        }
      }
      pricing {
        total
        subTotal
        deliveryCharge
        serviceCharge
      }
    }
  }
`;

export const GET_PATRON_ORDER = gql`
  query getPatronOrder($id: String) {
    getPatronOrder(id: $id) {
      _id
      pricing {
        subTotal
        deliveryCharge
        serviceCharge
        total
        initialTotal
      }
      location {
        seat
        row
        venue
        section
        collectionPoint
        venueName
        sectionName
      }
      vendorOrders {
        _id
        patronOrderID
        orderStatus
        vendor {
          _id
          tradingName
          phoneNumber
          imageUrl
        }
        items {
          count
          _id
          name
          isRejected
          isUpdated
          updatedQuantity
          customisations {
            _id
            name
          }
        }
        isOrderUpdated
        isClickAndCollectEnabled
        serviceMethod
        orderNumber
        createdAt
        updatedAt
        orderCurrency {
          name
          symbol
        }
      }
      isClickAndCollectEnabled
      serviceMethod
      orderNumber
      orderStatus
      createdAt
      updatedAt
    }
  }
`;

export const GET_ALL_PATRON_ORDERS = gql`
  query getAllPatronOrders($payload: PatronOrderQueryParams) {
    getAllPatronOrders(payload: $payload) {
      data {
        _id
        location {
          venue
          section
          seat
          row
          venueName
          sectionName
        }
        vendorOrders {
          _id
          orderNumber
        }
        orderStatus
        createdAt
        orderNumber
      }
      pagination {
        limit
        total
      }
    }
  }
`;

export const GET_PATRON = gql`
  query getPatron($payload: GetPatronInput!) {
    getPatron(payload: $payload) {
      _id
      name
      locationDetails {
        address
        buildingNumber
        floorNumber
      }
    }
  }
`;

export const GET_ALL_NOTIFICATIONS = gql`
  query getAllNotifications($payload: GetAllNotificationsQueryParams!) {
    getAllNotifications(payload: $payload) {
      _id
      subscriberID
      message
      seen
      body
      createdAt
      updatedAt
      type
    }
  }
`;

export const GET_VENDOR_ORDER = gql`
  query getVendorOrder($id: String!) {
    getVendorOrder(id: $id) {
      _id
      patronOrderID
    }
  }
`;

export const GET_WHITE_LABEL_CONFIG = gql`
  query getWhiteLabelConfig($input: ConfigInput!) {
    getWhiteLabelConfig(configInput: $input) {
      appId
      installationId
      displayName
      pageTitle
      pageDescription
      appLogoImageUrl
      faviconUrl
      landingImageUrl
      manifestUrl
      features {
        useNewMenu
        enableAzupay
      }
      venueSettings {
        sectionLabel
      }
    }
  }
`;



export const GET_MENU = gql`
query GetMenu($vendorID: ID!, $id: ID!) {
  VendorMenu(vendorID: $vendorID, id: $id) {
      id
      ownerID
      description
      items {
        id
        _id
        name
        imageUrl
        thumbImageUrl
        description
        price
        vendorID
        price
        outOfStock
      }
      imageUri
      itemGroups {
        id
        name
        itemIDs {
          itemID
          position
        }
        position
      }
      updatedOn
      createdOn
      isActive
    }
  }
`;

export const VALIDATE_PAY_ID = gql`
  query validatePayId($validatePayIdInput: ValidatePayIdInput!){
    validatePayId(validatePayIdInput: $validatePayIdInput) {
      valid
      payId
      payIdType
    }
  }
`

export const GET_PAYMENT_AGREEMENTS = gql`
  query getValidPaymentAgreements($patronId: String!, $requestAmount: Float!){
    getValidPaymentAgreements(patronId: $patronId, requestAmount: $requestAmount){
      maximumAmount
      paymentAgreementToken
      clientTransactionId
      status
      startDate
      endDate
      payerDetails {
        payId
      }
    }
  }
`

export const GET_AGREEMENT_STATUS = gql`
  query getAgreementStatus($patronId: String!, $agreementToken: String!) {
    getAgreementStatus(patronId: $patronId, agreementToken: $agreementToken)
  }
`;

export const GET_PMS_GUEST_INFO = gql`
  query GetGuestInfoFromPMS($venueID: String!, $integrationID: String!, $reservationID: String!) {
    GetGuestInfoFromPMS(venueID: $venueID, integrationID: $integrationID, reservationID: $reservationID) {
      guests {
        firstName
        lastName
        email
        phone
        mobile
      }
      reservationInfo {
        from
        to
        current
        status
      }
    }
  }
`;

