import React, { useEffect } from 'react';
import { createContext, ReactElement } from 'react';
import { Section, Vendor } from '../types';
import { IContextValue, useIContext } from './common';
import { ServiceMethod } from '../types/order';
import { Store } from '../components/vendor-menu/types';

export type WhiteLabelConfig = {
  appId: string;
  installationId: string;
  displayName: string;
  pageTitle: string;
  appLogoImageUrl: string;
  faviconUrl: string;
  landingImageUrl: string;
  pageDescription?: string;
  manifestUrl?: string;
  venueSettings?: VenueSettings;
  features?: {
    useNewMenu?: boolean;
    enableAzupay?: boolean;
  };
};

type VenueSettings = {
  sectionLabel: string;
};

type AppContextType = {
  hostname?: IContextValue<string>;
  whitelabelConfig?: IContextValue<WhiteLabelConfig>;
  section?: IContextValue<string>;
  sections?: IContextValue<Section[]>;
  collectionPoint?: IContextValue<string>;
  serviceMethod?: IContextValue<string>;
  row?: IContextValue<string>;
  seat?: IContextValue<string>;
  stadium?: IContextValue<string>;
  venueType?: IContextValue<string>;
  venueCode?: IContextValue<string>;

  isSectionClickAndCollectEnabled?: IContextValue<boolean>;
  patronAdmitRSALimit?: IContextValue<boolean>;
  venueCurrencySymbol?: IContextValue<string>;
  selectedVendor?: IContextValue<Vendor>;
  store?: IContextValue<Store>;
  ssoOTPVerificationFailures?: IContextValue<number>;
  clear: () => void;
};

export const AppContext = createContext<AppContextType>({
  clear: () => 0,
});

interface Props {
  children: ReactElement;
}

const localStorageAppDataKey = 'liively-app-data';

function AppContextProvider({ children }: Props) {
  const existingValue = JSON.parse(
    localStorage.getItem(localStorageAppDataKey) || `{}`,
  );

  const location = window.location.hostname;
  const hostname = useIContext<string>(location);
  const whitelabelConfig = useIContext<WhiteLabelConfig>(undefined);
  const section = useIContext<string>(existingValue?.section || '');
  const sections = useIContext<Section[]>(existingValue?.sections || '');
  const collectionPoint = useIContext<string>(
    existingValue?.collectionPoint || '',
  );
  const serviceMethod = useIContext<string>(existingValue?.serviceMethod || '');
  const row = useIContext<string>(existingValue?.row || '');
  const seat = useIContext<string>(existingValue?.seat || '');
  const stadium = useIContext<string>(existingValue?.stadium || '');
  const venueType = useIContext<string>(existingValue?.venueType || '');
  const venueCode = useIContext<string>(existingValue?.venueCode || '');
  const patronAdmitRSALimit = useIContext<boolean>(
    existingValue.patronAdmitRSALimit || false,
  );
  const venueCurrencySymbol = useIContext<string>(
    existingValue?.venueCurrencySymbol || '',
  );
  const isSectionClickAndCollectEnabled = useIContext<boolean>(
    existingValue?.isSectionClickAndCollectEnabled || false,
  );
  const selectedVendor = useIContext<Vendor>(existingValue?.selectedVendor);
  const store = useIContext<Store>(existingValue?.store);
  const ssoOTPVerificationFailures = useIContext<number>(0);

  const value: AppContextType = {
    hostname,
    whitelabelConfig,
    section,
    sections,
    collectionPoint,
    serviceMethod,
    row,
    seat,
    stadium,
    venueType,
    venueCode,

    isSectionClickAndCollectEnabled,
    patronAdmitRSALimit,
    selectedVendor,
    store,
    ssoOTPVerificationFailures,
    venueCurrencySymbol,

    clear: () => {
      section.clear();
      sections.clear();
      collectionPoint.clear();
      serviceMethod.clear();
      row.clear();
      seat.clear();
      stadium.clear();
      venueCode.clear();
      venueType.clear();
      isSectionClickAndCollectEnabled.clear();
      selectedVendor.clear();
      store.clear();
      ssoOTPVerificationFailures.clear();
      venueCurrencySymbol.clear();
      patronAdmitRSALimit.clear();
    },
  };

  useEffect(() => {
    localStorage.setItem(
      localStorageAppDataKey,
      JSON.stringify({
        stadium: value?.stadium?.value,
        venueCode: value?.venueCode?.value,
        venueType: value?.venueType?.value,
        row: value?.row?.value,
        seat: value?.seat?.value,
        section: value?.section?.value,
        sections: value?.sections?.value,
        collectionPoint: value?.collectionPoint?.value,
        serviceMethod: value?.serviceMethod?.value,
        isSectionClickAndCollectEnabled:
          value?.isSectionClickAndCollectEnabled?.value,
        patronAdmitRSALimit: value?.patronAdmitRSALimit?.value,
        selectedVendor: value?.selectedVendor?.value,
        store: value?.store?.value,
        venueCurrencySymbol: value?.venueCurrencySymbol?.value,
      }),
    );
  }, [value]);

  return <AppContext.Provider value={value}>{children}</AppContext.Provider>;
}

export default AppContextProvider;
